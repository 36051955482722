.cc {
  width: s(1920px);
  height: s(1080px);
  margin: s(15px);
  position: relative;
  overflow: hidden;
}

.defaultStyle {
  position: absolute;
  bottom: 10px;
  text-align: center;
}

.cueDirUniWrapper {
  padding: 5pt;
}

#captions {
  width: s(1920px);
  height: s(1080px);
  margin: 0;

  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  overflow: hidden;
}
