.thumbnail-preview {
  height: s(242px);
  width: s(455px);
  position: absolute;
  background-color: black;
  bottom: s(510px);
  border-radius: s(8px);
  border: s(6px) solid rgb(255, 255, 255);
  box-shadow: 0px s(8px) s(16px) 0px rgba(0, 15, 30, 0.12);

  .thumbCanvas {
    width: s(443px);
    height: s(230px);
    overflow: hidden;
  }

  .hide {
    display: none;
  }

  .control-time__spent {
    position: absolute;
    left: s(170px);
    bottom: s(10px);
  }
}
