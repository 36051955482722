.card--live.card {
  height: $live-card-height;
  width: $live-card-width;

  .card__inner {
    height: $live-card-height;
    text-align: center;

    &__thumb {
      .image-wrapper {
        width: $live-card-width;
        height: $live-card-height;
      }
    }

    &__details {
      padding: 0 s(48px) s(48px);

      &::before {
        display: none;
      }

      h3 {
        margin-top: s(16px);
        font-size: s(24px);
        line-height: s(36px);
        color: $rb_white;
        transition: $card-transition;
        // will-change: color;
      }

      > .card-panel {
        position: static;
        display: inline-block;
      }
    }
  }

  &.is-focused {
    .card__inner {
      &__details {
        h3 {
          color: $rb-alabaster;
        }
      }
    }
  }
}
