.universalPlayer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object,
  video {
    width: 100%;
    height: 100%;
  }

  .dashPlayer {
    top: 0px;
    position: absolute;
    left: 0px;
  }

  .html5HLSPlayer {
    top: 0px;
    position: absolute;
    left: 0px;
  }

  #ad-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
  }
}
