.empty-states-screen {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: s(1920px);
  height: s(1080px);
  background: url('../images/dialog-1.jpg');
  background-size: 100% 100%;
  //background-color: green;
}

.empty-state {
  position: absolute;
  top: 0;
  left: 0;
  width: s(1920px);
  height: s(1080px);
  z-index: 99;
  background: $INTRO_BG_COLOR;
}

// DONE
.no-search-results__text {
  position: absolute;
  top: s(280px);
  left: s(300px);
  color: $rb-white;
  font-weight: 600;
  font-size: s(39px);
}

.connection-timeout {
}

// DONE
.no-connection {
  background-color: $pure-black;

  &__message-wrapper {
    height: s(300px);
    width: s(720px);
    margin: 0 auto s(56px) auto;
    box-sizing: border-box;
    font-size: s(44px);
  }

  &__center-box {
    margin: s(332px) auto 0 auto;
    text-align: center;

    .regular-button {
      display: inline-block;
    }
  }

  .no-connection__button {
    margin-top: s(91px);
    height: s(120px);
    width: s(720px);
    background-color: #e2e3e5;
  }

  &__icon {
    /* margin-bottom: s(30px); */

    .lightning-icon {
      svg {
        width: s(120px);
        height: s(120px);
      }
    }

    > svg {
      width: s(200px);
      height: s(200px);
    }

    &-fill {
      fill: $rb_white;
    }
  }

  &__headline {
    color: $rb-white;
    font-size: s(60px);
    font-family: $cosmos-font-family-text;
    font-weight: $cosmos-font-weight-bold;
  }

  &__text {
    margin-bottom: s(8px);
    color: $rb-white;
    font-family: $cosmos-font-family-text;
    font-weight: $cosmos-font-weight-regular;
    font-size: s(30px);
  }

  &__device_not_supported_text {
    font-size: s(35px);
    margin-bottom: s(50px);
    color: $rb-white;
  }

  &__device_not_supported_text_line {
    margin-bottom: s(15px);
    margin-top: s(15px);
  }
}

.video-area {
  z-index: -1;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  width: s(1920px);
  height: s(1080px);
  overflow: hidden;
}

#error-video {
  bottom: 0;
  position: absolute;
  width: 100%;
}
