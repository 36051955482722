.card--channelEpg {
  height: $channel-card-height-epg;
  width: $channel-card-width-epg;
  border-style: none;

  .card__cellTitle {
    font-size: s(18px);
    line-height: 1;
    font-weight: 600;
    color: $rb-iron;
    text-transform: uppercase;
    margin: 0;
  }

  .card__cell {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .card__details {
    display: block;
    padding: s(24px) s(24px);
    width: 100%;
    height: 100%;
    min-height: auto;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    &::before {
      display: none;
    }
  }

  .card__inner {
    height: $channel-card-height-epg;
  }

  .card__thumb {
    position: absolute;
    width: auto;
    height: auto;
    top: $card-offset-y;
    right: $card-offset-x;
    left: $card-offset-x;
    bottom: $card-offset-y;
    transition: $card-transition;
    border-radius: $card-border-radius;
    perspective: 1px;
    overflow: hidden;
    z-index: 0;

    .image-wrapper {
      display: block;
      position: absolute;
      top: $card-offset-y * -1;
      left: $card-offset-x * -1;
      width: $channel-card-width-epg;
      height: $channel-card-height-epg;
      transition: $card-transition;
      background-color: $pure-black;

      &--loading {
        background-color: rgba($rb-grey-100, 0.8);
      }

      img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: $card-transition;
        opacity: 0.5;
      }
    }
  }
}
