.playlist-card-epg {
  position: relative;
  height: $playlist-card-epg-height;
  width: $playlist-card-epg-width;
  float: left;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  margin-top: s(2px);

  .playlist-card-epg__text-container--empty {
    height: s(10px);
    margin-top: s(20px);
  }

  .playlist-card-epg__details--empty {
    margin-left: s(40px);
    position: relative;
    height: s(95px);
    top: s(30px);
    left: s(50px);

    .empty-string {
      position: relative;
      left: s(25px);
      margin: 0;
      font-size: s(21px);
      font-weight: 600;
      line-height: s(28px);
      color: $rb_white;
      display: inline;
    }

    .icon-alert {
      font-size: s(30px);
      color: 'white';
    }
  }

  &__details {
    position: relative;
    overflow: hidden;
    height: s(95px);
    top: s(28.5px);
    left: s(20px);
    width: s(427.5px);

    .details-title {
      margin: s(3px) 0 s(3px) 0;
      font-size: s(21px);
      font-weight: 600;
      line-height: s(28px);
      color: $rb_white;
    }

    .details-subheading {
      margin: s(0px);
      font-size: s(18px);
      font-weight: 400;
      line-height: s(28px);
      color: $rb-grey-400;
    }

    .details-time {
      margin: s(0px) s(0px) s(0px) s(0px);
      font-size: s(18px);
      font-weight: 100;
      line-height: s(28px);
      color: $rb-grey-400;

      .is-live {
        display: inline-block;
        position: relative;
        font-weight: 400;
        line-height: inherit;
        width: s(16px);
        height: s(16px);
      }
    }
  }

  &__inner {
    position: absolute;
    height: 100%;
    width: 100%;
    padding-left: s(14px);
    overflow: hidden;
    z-index: 1;

    .progress-card {
      position: absolute;
      width: 100%;
      bottom: s(4px);
    }
    &.inner--servus {
      &::before {
        background-color: #000000;
        opacity: 0.5;
      }
    }

    &::before {
      content: '';
      position: absolute;
      width: auto;
      top: $playlist-card-offset-y;
      right: $playlist-card-epg-offset-x;
      bottom: $playlist-card-epg-offset-y;
      left: $playlist-card-epg-offset-x;
      border-radius: $card-border-epg-radius !important;
      background-color: #1e2731;
      transition: $card-transition;
      overflow: hidden;
      opacity: 0.6;
      z-index: -1;
    }
  }

  &.playlist-card-epg--empty {
    width: 120%;
  }

  &.playlist-card-epg--is-focused {
    height: $playlist-card-epg-height + s(15px);

    .playlist-card-epg__details--empty {
      top: calc($playlist-card-epg-height / 2) - calc($playlist-card-epg-offset-x / 2) - s(29px) !important;

      .empty-string {
        color: $pure-black;
      }
      .icon-alert {
        color: $rb-red;
      }
    }

    .playlist-card-epg__details {
      top: calc($playlist-card-epg-height / 2) - calc($playlist-card-epg-offset-x / 2) - s(29px) !important;

      .details-title {
        color: $pure-black;
        width: 250%;
      }

      .details-subheading {
        color: $pure-black;
        width: 250%;
      }
      .details-time {
        color: $pure-black;
        .is-live {
          color: $rb-red;
        }
      }
      .titleScroll {
        animation: marquee2 5s linear infinite !important;
        height: s(30px);
        white-space: nowrap;
      }
      .subTitleScroll {
        animation: marquee2 5s linear infinite !important;
        height: s(30px);
        white-space: nowrap;
      }
      @keyframes marquee2 {
        0% {
          transform: translate3d(0%, 0, 0);
        }
        100% {
          transform: translate3d(-100%, 0, 0);
        }
      }
    }

    .playlist-card-epg__inner {
      &::before {
        background-color: $rb-alabaster;
        opacity: 1;
        overflow: hidden;
      }
    }
  }
}
