.info-container {
  position: absolute;
  display: table;
  bottom: 0;
  left: 0;
  height: 50%;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.55) 70%, transparent);

  &__data-container {
    width: 80%;
    height: 100%;
    display: table-cell;
    vertical-align: bottom;
    position: relative;
    bottom: s(45px);

    .details-container {
      width: 80%;
      margin-left: s(80px);

      &__inline-data {
        display: block;
        margin-top: s(10px);
      }
    }

    .description-container {
      width: 65%;
      max-height: s(200px);
      overflow: hidden;
    }

    .title-container {
      width: 70%;
    }

    .separator {
      display: inline;
      font-size: s(15px);
      font-weight: 600;
      line-height: s(28px);
      color: $rb-grey-300;
    }

    .title {
      margin-bottom: s(25px);
      font-size: s(45px);
      font-weight: 600;
      line-height: s(40px);
      color: $pure-white;
    }
    .time {
      display: inline;
      margin: s(5px);
      font-size: s(30px);
      font-weight: 600;
      line-height: s(28px);
      color: $rb-grey-300;
    }
    .desc {
      margin: s(5px);
      font-size: s(29px);
      font-weight: 100;
      line-height: s(40px);
      color: $pure-white;
      &.servus {
        font-size: s(24px) !important;
      }
    }
    .lowercase {
      text-transform: lowercase;
    }
  }
}
