.icon {
  @include icon;
  position: absolute;
  top: $tv-height - s(454px + 40px + 20px + 48px + 40px + 60px);
  left: s(300px - 64px - 20px);
  background-color: transparentize(red, 0.4);
}

.column {
  position: absolute;
  width: s(720px);
  height: s(400px);

  &__left {
    bottom: s(454px);
    left: s(100px + 80px + 120px);
    background-color: yellow;
  }

  &__right {
    top: s(430px);
    right: s(100px);
    background-color: green;
  }
}

.wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: auto;
  background-color: orange;
}

.information {
  &__prompt {
    //font-weight: 600;
    background-color: red;
    color: transparentize($pure-white, 0.5);
    font-size: s(58px);
    line-height: s(42px);
  }

  &__title {
    margin-top: s(40px);
    height: s(48px);
    background-color: green;
    color: $rb_white;
    font-size: s(48px);
    //font-weight: 400;
  }

  &__subtitle {
    margin-top: s(20px);
    height: s(40px);
    background-color: blue;
    color: transparentize($pure-white, 0.5);
    font-weight: 400;
    font-size: s(40px);
  }
}

.buttonDialog {
  .textContent {
    position: absolute;
    text-align: center;
    top: s(235px);
    left: s(500px);
    width: s(900px);
    z-index: 20;
    color: $rb-alabaster;

    > h3 {
      font-size: s(32px);
      opacity: 0.8;
      margin-bottom: s(13px);
    }
    > h1 {
      font-size: s(48px);
      margin-bottom: 5px;
    }
    > h2 {
      font-size: s(40px);
      margin-bottom: s(13px);
    }
  }

  .buttons {
    position: absolute;
    top: s(575px);
    left: s(599px);
    z-index: 20;
    > .button {
      width: s(500px);
      margin-bottom: s(20px);
      background-color: $rb-grey-100;
      &.is-focused {
        background-color: $rb-white;
      }
    }
  }

  &--envs {
    .textContent {
      top: s(135px);
    }

    .buttons {
      top: s(375px);
    }
  }
}

.message-dialog {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $rb-blue-charcoal;
  opacity: 0.88;
  z-index: 10;

  &__container {
    width: 100%;
    height: auto;
    position: relative;
    top: s(320px);
  }

  &__icon {
    display: block;
    width: s(192px);
    height: s(192px);
    margin: 0 auto;
  }

  &__message {
    width: s(1120px);
    margin: 0 auto;

    h1 {
      color: $rb-alabaster;
      font-weight: 700;
      font-size: s(68px);
      text-align: center;
      margin: 0;
    }
  }
}
