.playlist {
  width: s(5000px);
  height: s(168px);
  position: absolute;
  bottom: s(72px);
  left: s(300px);
  opacity: 1;

  &__header {
    height: s(48px);

    h1 {
      margin: 0 0 s(16px) 0;
      color: $rb-alabaster;
      font-family: $cosmos-font-family-text;
      font-weight: $cosmos-font-weight-medium;
      font-size: s(28px);
      line-height: s(32px);
      height: s(32px);
      width: auto;
      position: absolute;
      top: 0;
      left: 0;
      text-transform: uppercase;
    }
  }

  .progress-bar {
    top: 0;
  }

  &__list {
    height: $playlist-card-height;
    margin-left: s(-10px);
    width: s(1000000px);
    // will-change: left, transform;
  }

  &--active {
    .playlist__list {
      transition: left 150ms ease, transform 150ms ease;
    }
  }

  .list__item {
    margin-right: s(24px);
  }

  @at-root .is-servus & {
    > .playlist__list > .playlist-card {
      margin-right: s(120px);
    }
  }
}

#list__name-measure {
  position: absolute;
  left: -9999px;
  top: -9999px;
  display: inline-block;
  font-size: s(35px);
  font-weight: 600;
  color: $rb-white;
  line-height: 1;
}

.center-list {
  .list {
    text-align: center;
  }
}
