.countdown {
  display: block;

  span {
    margin-left: 0.25em;
    &:first-child {
      margin-left: 0;
    }

    i {
      font-family: 'BullMono-Bold', monospace, sans-serif;
      font-weight: normal;
      font-style: normal;
    }
  }
}
