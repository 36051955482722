$control-item-width: s(100px);
$control-height: s(80px);

$control-focus-size: s(80px);
$control-focus-size--large: s(100px);
$control-focus-radius: s(40px);
$control-focus-radius--large: s(50px);

$control-primary-width: s(555px);
$control-primary-item-size: s(100px);

$control-bar-height: s(6px);

// MARKUP STARTS

.controls {
  position: absolute;
  width: 100%;
  top: s(524px);
  bottom: 0;

  z-index: 50;
  color: $rb-white;
  //transition: opacity $animation-time $animation-curve, transform $animation-time $animation-curve;

  .has-tabs & {
    opacity: 0;
  }

  .has-ffr & {
    > div:not(.control-status) {
      opacity: 0;
    }
  }
}

.control-status {
  padding: 0px s(300px);
}

.control-primary,
.control-secondary {
  margin: 0 auto s(-10px);
  width: $control-primary-width;
  text-align: center;
  display: block;
}

.control-secondary {
  padding-top: s(30px);
}

.control-primary__item {
  width: $control-primary-item-size;
  height: $control-primary-item-size;
  line-height: $control-primary-item-size;
  position: relative;
  display: inline-block;

  .control-primary & {
    width: $control-focus-size--large;
    height: $control-focus-size--large;
    border-radius: $control-focus-radius--large;
  }

  > img {
    @include icon;
    // class containing word icon

    [class*='icon'] {
      fill: $rb-grey-300;
    }
    vertical-align: middle;
  }

  > img {
    /*width: s(48px);*/
    height: s(64px);
    width: auto;
  }

  &.is-focused {
    [class*='icon'] {
      fill: $rb-white;
    }
    position: relative;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: $control-focus-radius--large;
  }

  &.live {
    img {
      height: s(33px);
      width: s(64px);
    }
  }
}

.control-time {
  margin-bottom: $gutter;
  font-size: s(32px);
  color: $rb-alabaster;
  text-transform: uppercase;

  .has-ffr & {
    position: relative;
    top: s(-347px);
  }

  &--live {
    color: $rb-red;
  }

  &--dvr {
    font-size: s(26px);
    font-weight: 600;

    .control-time__start {
      color: $rb-white;
    }

    .control-time__timediff {
      color: $rb-white;
      position: absolute;
      right: s(190px);
    }
  }

  &__spent {
    font-size: s(26px);
    font-weight: 600;
  }

  &__total {
    font-size: s(26px);
    font-weight: normal;
  }
}

// status bars´
.control-time-bar {
  position: relative;
  height: $control-bar-height;
  width: s(1320px);
  background-color: rgba(255, 255, 255, 0.4);

  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: $pure-white;
  }
}

.cardHitAreaRound {
  border-radius: $control-focus-radius--large;
}
