$padding: 140px;

.privacy-consent-prompt {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: s($padding - 42px) s($padding) 0;
  background-color: rgba($rb-blue-charcoal, 0.95);
  color: $rb-alabaster;
  z-index: 9999;

  &__content {
    &--grid {
      &::before,
      &::after {
        content: '';
        display: table;
        width: 100%;
      }

      &::after {
        clear: both;
      }
    }

    &__left {
      float: left;
      width: 61.4%;
      padding-right: s($padding);
    }

    &__right {
      float: right;
      width: 38.6%;
    }
  }

  p {
    font-size: s(24px);
    line-height: s(36px);
    margin: 0 0 s(40px);
  }

  strong {
    font-weight: 700;
  }

  .privacy-consent-prompt {
    &-el {
      &--title {
        font-size: s(60px);
        line-height: s(90px);
        margin: 0 0 s(50px);
      }

      &--buttons {
        margin-top: s(60px);

        &::before,
        &::after {
          content: '';
          display: table;
          width: 100%;
        }

        &::after {
          clear: both;
        }

        &-left {
          float: left;
        }

        &-right {
          float: right;
        }

        .regular-button {
          max-width: s(416px);

          &__inner {
            font-weight: bold;
            text-transform: uppercase;
          }
        }
      }

      &--save {
        margin-top: s(100px);

        &::before,
        &::after {
          content: '';
          display: table;
          width: 100%;
        }

        &::after {
          clear: both;
        }

        &-left {
          float: left;
        }

        &-right {
          float: right;
        }

        .regular-button {
          max-width: s(422px);
          text-transform: uppercase;

          &__inner {
            font-weight: bold;
            text-transform: uppercase;
          }
        }
      }

      &--navigation {
        margin-top: s(36px);
      }
    }
  }

  .regular-button {
    &::before {
      background-color: $rb-iron;
    }

    .regular-button__inner {
      color: $search-field-color;
    }

    &.is-focused {
      &::before {
        background-color: $servus-red;
      }

      .regular-button__inner {
        color: $rb-iron;
      }
    }

    &--switch {
      width: 100%;
      margin-bottom: s(40px);

      &::before {
        background-color: transparent;
      }

      .regular-button__inner {
        color: $pure-white;
      }

      &.is-focused {
        &::before {
          background-color: $rb-iron;
        }

        .regular-button__inner {
          color: $search-field-color;
        }
      }
    }
  }

  .text-page {
    top: s(-20px);
    left: s(-230px);
    width: s(2300px);
  }
}
