@mixin landing-page() {
  position: absolute;
  top: 0px;
  left: 0;
  width: s(1920px);
  height: s(1080px);
  z-index: 1;

  overflow: hidden;
  background-color: $rb-blue-charcoal;
  // transition: $card-transition;

  // Just to make child divs on top of the overlay
  > div {
    // position: relative;
    z-index: 2;
  }

  .video-preview-wrapper {
    display: inline-block;
    overflow: hidden;
    position: absolute;
    top: 0px;
  }

  .video-preview-wrapper video {
    width: s(1920px) !important;
  }
}

.landing-page {
  @include landing-page();

  .page-buttons {
    margin-left: s(16px);
  }

  @at-root .is-servus & {
    .landing-page__title,
    .landing-page__subtitle,
    .landing-page__desc {
      font-family: Redbull;
    }
  }
}

.landing-page__aside {
  width: s(200px);
  position: absolute;
  top: s(13px);
  left: s(100px);
}

.landing-page__back {
  margin-top: s(500px);
  img {
    @include icon;
  }
}

.landing-page__meta {
  position: absolute;

  span {
    position: absolute;
    top: s(515px);
    left: s(100px);
    color: $rb-white;
    font-weight: 300;
    font-size: s(30px);
  }
}

.landing-page__main {
  width: s(1736px);
  position: absolute;
  left: s(184px);
}

.landing-page__desc {
  font-weight: bold;
}

.landing-page__subtitle {
  font-weight: bold;
}

.landing-page-image {
  position: absolute;
  top: 0;
  left: 0;
  width: s(1920px);
  height: s(1080px);

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.8) -10%, transparent 100%);
    background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 0.8) -10%, transparent 100%);
  }

  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
  }
}

.landing-page-transparent {
  background-color: transparent;
}

.landing-page__desc__ellipsis {
  max-height: 25rem;
  overflow: hidden;
}
