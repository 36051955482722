.card.card--featured-small {
  width: $featured-card-small-width;
  height: $featured-card-small-height;

  .card__inner {
    height: $featured-card-small-height;

    &__thumb {
      .image-wrapper {
        width: $featured-card-small-width;
        height: $featured-card-small-height;
      }

      &__title-treatment {
        position: absolute;
        bottom: $card-offset-y + s(16px);
        left: $card-offset-x + s(16px);
        width: s(112px);
        height: s(56px);
        transition: $card-transition;
        // will-change: bottom, left;

        img {
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          max-width: 100%;
          max-height: 100%;
          // object-fit: contain;
        }
      }
    }

    .card-panel {
      bottom: $card-offset-y + s(16px);
      right: $card-offset-x + s(16px);
      transition: $card-transition;
      // will-change: right, bottom;
    }
  }

  .title-marquee {
    position: relative;
    // &.is-marqueed {
    //   &::after {
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     background: linear-gradient(90deg, $pure-black 0%, rgba(0, 0, 0, 0) 15%), linear-gradient(90deg, rgba(0, 0, 0, 0) 85%, $pure-black 100%);
    //     background: -webkit-linear-gradient(0deg, $pure-black 0%, rgba(0, 0, 0, 0) 15%), -webkit-linear-gradient(0deg, rgba(0, 0, 0, 0) 85%, $pure-black 100%);
    //     pointer-events: none;
    //   }
    // }

    h3 {
      margin: 0 5%;
      padding: s(23px) 0 0;
      font-size: s(28px);
      font-weight: 400;
      line-height: s(42px);
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $rb-white;

      .marquee {
        display: block;
        width: fit-content;
        animation: marquee 15s 0.5s linear infinite;
        // will-change: transform;

        > span {
          margin-right: s(40px);
        }
      }
    }
  }

  &.is-focused .card__inner {
    &__thumb {
      &__title-treatment {
        bottom: s(16px);
        left: s(16px);
      }
    }

    .card-panel {
      bottom: s(16px);
      right: s(16px);
    }
  }
}

@keyframes marquee {
  0% {
    transform: translate3d(0%, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}
