$grid-page-left-offset: s(176px);

.grid-page {
  position: absolute;
  left: $grid-page-left-offset - $main-content-left-offset;

  .grid-panel {
    width: s(392px) * 4; // Width of 4 channel cards
    margin: 0px auto;
  }

  .grid-panel-scrolled {
    margin-top: s(-400px);
  }

  .list__name {
    font-size: s(40px);
    height: s(48px);
    font-weight: 600;
    margin: s(64px) s(16px) s(32px) s(16px);
    color: $grid-page-list-name-color;
  }

  .video-card {
    float: left;
    opacity: 1;
  }

  .page-card,
  .universal-card {
    float: left;
    opacity: 1;
  }

  .isLinearChannelCard {
    float: left;
    margin-left: s(120px);
  }

  &--viewAll {
    width: s(1686px);
  }

  @at-root .is-servus & {
    .list__name {
      text-transform: uppercase;
    }
  }
}
