.card--channel.card {
  height: $channel-card-height;
  width: $channel-card-width;

  @at-root .is-servus & {
    .card__inner {
      &__details {
        &__cell {
          text-align: left;
          vertical-align: bottom;
          padding: s(16px);
          transition: $card-transition;
          // will-change: padding;
        }
      }
    }

    &.is-focused .card__inner {
      .card__inner {
        &__details {
          &__cell {
            padding: 0;
          }
        }
      }
    }
  }

  .card__inner {
    height: $channel-card-height;

    &__thumb {
      .image-wrapper {
        height: $channel-card-height;
        width: $channel-card-width;
      }
    }

    &__details {
      display: table;
      padding: $card-offset-y * 1.5 $card-offset-x * 1.5;
      width: 100%;
      height: 100%;
      min-height: auto;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;

      &::before {
        display: none;
      }

      &__cell {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
      }

      h3 {
        font-size: s(26px);
        line-height: 1;
        font-weight: 600;
        color: $rb-iron;
        text-transform: uppercase;
        margin: 0;
        // will-change: color;
      }
    }
  }

  &.is-focused .card__inner {
    &__details {
      h3 {
        color: $rb-alabaster;
      }
    }
  }
}

.card--channel--linear.card {
  height: $linear-channel-card-height;
  width: $linear-channel-card-width;

  .card__inner {
    height: $linear-channel-card-height;

    &__thumb {
      .image-wrapper {
        height: $linear-channel-card-height;
        width: $linear-channel-card-width;
      }
    }

    &__details {
      h3 {
        font-size: s(22px);
      }
    }
  }
}

// .card--channel--underlined.card {
//   &.is-focused .card__inner {
//     &__details {
//     }
//   }
// }
