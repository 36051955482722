$hint-height: s(72px);
$hint-screen-edge-spacing: s(64px);

@keyframes fadeIn {
  0%,
  100% {
    opacity: 0;
  }
  15%,
  85% {
    opacity: 1;
  }
}

@keyframes fadeInBack {
  0%,
  100% {
    opacity: 0;
  }
  15%,
  85% {
    opacity: 0.2;
  }
}

.hints {
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $rb-black;
    content: '';
    opacity: 0;

    &--fade {
      animation: fadeInBack;
    }

    &--indefinite::after {
      opacity: 1;
    }
  }
  .hidden {
    display: none;
  }
}

.hint {
  position: absolute;
  width: 100%;
  opacity: 0;
  z-index: 51;
  pointer-events: none;

  .dashboard & {
    opacity: 0; // Change this to 1 to get the hint showing on dashboard
  }

  .tab-overlay & {
    opacity: 0; // Change this to 1 to get the hint showing on Tabs
  }

  &.indefinite {
    opacity: 1;
  }

  &.hint--fade {
    animation: fadeIn;
  }

  &--back {
    bottom: $hint-screen-edge-spacing;
    left: $hint-screen-edge-spacing;
  }

  &--right {
    top: calc(($tv-height / 2))- calc(($hint-height / 2));
    right: $hint-screen-edge-spacing;

    &.is-visible {
      position: absolute;
    }
  }

  &--left {
    top: calc(($tv-height / 2)) - calc(($hint-height / 2));
    left: $hint-screen-edge-spacing;
  }

  &--transparent {
    background-color: transparent;
  }

  &--bottom {
    bottom: $hint-screen-edge-spacing;
  }

  &--top {
    top: $hint-screen-edge-spacing;
  }
}

.hint-body {
  .hint--left & {
    position: absolute;
    left: 0;
    text-align: left;
  }

  .hint--right & {
    position: absolute;
    right: 0;
    text-align: right;
  }
}

.hint-body__label {
  color: $pure-white;
  font-size: s(30px);
  line-height: s(24px);

  .hint--top & {
    text-align: center;
  }

  .hint--bottom & {
    text-align: center;
  }

  .hint--left & {
    display: inline-block;
  }

  .hint--right & {
    display: inline-block;
  }
}

.hint-body__icon {
  width: s(48px);
  height: s(48px);

  > img {
    width: s(48px);
    height: s(48px);
  }

  &--up {
    margin: 0 auto;
  }

  &--right {
    top: s(15px);
    position: relative;
    transform: rotate(90deg);
    display: inline-block;
  }

  &--down {
    margin: 0 auto;
    transform: rotate(180deg);
  }

  &--left {
    top: s(15px);
    position: relative;
    transform: rotate(270deg);
    display: inline-block;
  }
}
