.live-guide {
  &.drawer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50%;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 15, 30, 0.0001) 0%, #000f1e 100%);
    overflow: hidden;
  }
  &.translate {
    animation: 0.5s ease-out 0s 1 slideIn;
  }

  &__item-container-epg {
    margin-left: 5%;
    margin-right: 5%;
    display: table;
  }

  &__date-container {
    display: table;
    border-spacing: s(20px);
    margin-left: s(50px);
  }
  &__hidden-row {
    display: 'table';
    width: 600px;
    height: $playlist-card-epg-height + s(15px);
    margin-bottom: s(4px);
  }
}

.channel-row {
  display: table;
  width: 300000px;
  margin-bottom: s(4px);

  &__card-container {
    height: 100%;
    width: $channel-card-width-epg;
    margin-top: -2px;
  }

  &__hidden-container {
    position: relative;
    overflow: hidden;
  }

  &__items-container {
    height: 100%;
  }

  &__hidden-item {
    position: relative;
    height: $playlist-card-epg-height;
    width: $playlist-card-epg-width;
    float: left;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideOut {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(200%);
  }
}
