.card--page.card {
  &.has-title {
    .card-panel {
      bottom: s(137px);
      right: $card-offset-x + s(16px);
      transition: $card-transition;
      // will-change: right;
    }

    &.is-focused {
      .card-panel {
        right: s(16px);
      }
    }
  }

  @at-root .is-servus & {
    .card__inner {
      text-align: center;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: $card-offset-y + s(16px);
        right: $card-offset-x + s(16px);
        bottom: $card-offset-y + s(16px);
        left: $card-offset-x + s(16px);
        border: 1px solid;
        border-radius: $card-border-radius;
        color: $rb_white;
        transition: $card-transition;
        pointer-events: none;
        // will-change: top, right, bottom, left, color;
      }

      &__details {
        padding: s(15px) s(48px) s(36px);
        min-height: auto;

        &::before {
          display: none;
        }

        h3 {
          font-size: s(24px);
          line-height: s(36px);
          color: $rb_white;
          transition: $card-transition;
          // will-change: color;
        }

        > .card-panel {
          position: static;
          display: inline-block;
          margin-bottom: s(8px);
        }

        > .card-progress-bar {
          top: auto;
          bottom: 0;
          border-bottom-right-radius: $card-border-radius;
          border-bottom-left-radius: $card-border-radius;
        }
      }
    }

    &.is-focused {
      .card__inner {
        &::before {
          top: s(16px);
          right: s(16px);
          bottom: s(16px);
          left: s(16px);
          color: $pure-white;
        }

        &__details {
          h3 {
            color: $rb-alabaster;
          }
        }
      }
    }
  }
}
