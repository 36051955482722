.sunset-message {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: s(118px);
  background-color: $rb-blue-charcoal;
  background-image: url(../images/sunset-message-prophesy.jpg);
  background-size: 100% 100%;
  color: $rb-alabaster;
  z-index: 9999;

  &__wrapper {
    max-width: s(1028px);
  }

  &__logo {
    width: s(153px);
    height: s(64px);
    background-image: url(../images/rbtv_logo_light_white.png);
    background-size: contain;
  }

  &__title {
    font-size: s(72px);
    line-height: s(84px);
    padding: s(15px) 0;
  }

  &__descr {
    font-size: s(32px);
    line-height: s(47px);
  }

  &__button {
    width: s(292px);
    height: s(108px);
    margin-top: s(37px);

    & > div {
      line-height: s(96px);
    }
  }

  &--final {
    background-image: url(../images/sunset-message-final.jpg);

    .sunset-message__wrapper {
      max-width: s(1128px);
      position: absolute;
      bottom: s(118px);
    }
  }
}
