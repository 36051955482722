.card--universal.card {
  &.has-title {
    .card-panel {
      bottom: s(137px);
      right: $card-offset-x + s(16px);
      transition: $card-transition;
      // will-change: right;
    }

    &.is-focused {
      .card-panel {
        right: s(16px);
      }
    }
  }
}
