.page-buttons {
  position: relative;
  left: s(-8px);
  margin-top: 0;

  .page-button {
    padding: s(8px);
    display: inline-block;
    border-radius: s(8px);
    height: s(104px);
    white-space: nowrap;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: s(2px);
      right: s(5px);
      bottom: s(2px);
      left: s(5px);
      background-color: $rb-new-back;
      border-radius: $card-border-radius;
      transition: top $animation-time--fast $animation-curve,
        right $animation-time--fast $animation-curve, left $animation-time--fast $animation-curve,
        bottom $animation-time--fast $animation-curve;
      z-index: 0;
      // will-change: background-color, top, right, bottom, left;
    }

    &.is-focused {
      &::before {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $rb-red;
      }
    }
  }

  .page-button__innerWrapper {
    position: relative;
    line-height: s(88px);
    height: s(88px);
    padding: 0 s(32px) 0 s(20px);
    z-index: 1;

    .page-button__icon {
      float: left;
      position: relative;
      top: s(12px);
    }

    .page-button__label {
      line-height: s(88px);
      display: inline-block;
      margin-left: s(12px);
      font-size: s(26px);
      text-transform: uppercase;
      font-weight: 600;
      color: $rb-alabaster;
    }
  }
}
