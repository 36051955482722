.card-row {
  position: relative;

  > .row-wrapper {
    position: relative;
    width: s(1000000px);

    &.focused {
      > .row {
        transition: left 150ms ease, transform 150ms ease;
      }
    }

    > .row {
      position: relative;
      top: 0;
      left: 0;
      width: s(1000000px);
      // will-change: left, transform;

      > * {
        display: block;
        float: left;
      }

      &::before,
      &::after {
        content: '';
        display: table;
        width: 100%;
      }

      &::after {
        clear: both;
      }
    }

    &.FeaturedCard {
      height: $featured-card-height;
    }

    &.PageCard,
    &.UniversalCard {
      height: $card-height;
    }

    &.VideoCard {
      height: max($video-card-height, $card-height);
    }

    &.ChannelCard,
    &.MixedChannelCard {
      height: $channel-card-height;
    }

    &.LinearChannelCard {
      height: $linear-channel-card-height;
    }

    &.PlaylistCard {
      height: $playlist-card-height;
    }

    &.LiveCard {
      height: $live-card-height;
    }
  }

  &-header {
    height: s(144px);

    &-title {
      position: absolute;
      top: s(64px);
      left: s(16px);
      display: block;
      width: auto;
      height: s(48px);
      color: $card-row-header-color;
      font-weight: 600;
      font-size: $page-subtitle;
    }
  }

  @at-root .is-servus & {
    &-header {
      &-title {
        text-transform: uppercase;
        font-size: s(48px);
        line-height: s(72px);
      }
    }
  }
}
