.buttons-container {
  padding: s(20px);
  display: inline-block;
  margin-left: s(-40px);
  border-spacing: s(20px);
  margin-bottom: s(40px);
  height: s(110px);
}

.info-button {
  display: table-cell;
  margin-right: s(24px);
  position: relative;
  white-space: nowrap;
  padding: s(4px) s(10px);
  width: s(120px);
  height: s(110px);

  .icon-button {
    margin-top: s(20px);
    width: s(60px);
    height: auto;
  }

  .icon-small {
    width: s(32px);
    margin-bottom: s(10px);
    height: auto;
  }

  &::before {
    content: '';
    height: s(110px);
    position: absolute;
    top: s(2px);
    right: s(5px);
    bottom: s(2px);
    left: s(5px);
    background-color: #0d2237;
    border-radius: $card-border-radius;
    transition: top $animation-time--fast $animation-curve,
      right $animation-time--fast $animation-curve, left $animation-time--fast $animation-curve,
      bottom $animation-time--fast $animation-curve;
    z-index: 0;
  }

  &.is-servus {
    &::before {
      background-color: $servus-black;
    }
  }

  &.is-focused {
    height: s(110px);
    &::before {
      background-color: $rb-red;
    }

    &.is-servus {
      &::before {
        background-color: $servus-red;
      }
    }

    .icon-button {
      margin-left: s(24px);
      margin-right: s(10px);
    }

    .no-label {
      width: s(60px);
      margin-left: s(19px);
      margin-right: s(15px);
    }

    .icon-small {
      margin-left: s(37px);
      margin-right: s(15px);
    }
    .singleButton-margin {
      margin-top: s(30px);
    }

    .info-button__inner {
      color: $rb-alabaster;
      height: 100%;
    }
    .icon-label {
      display: inline-block;
      margin-right: s(40px);
    }
  }

  &__inner {
    color: $rb-iron;
    text-align: center;
    font-family: $cosmos-font-family-text;
    font-weight: $cosmos-font-weight-medium;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
  }

  .icon-label {
    font-size: s(25px);
    color: $rb_white;
    transform: translate(0px, s(-20px));
  }
}
