.settings-captions-preview {
  width: s(760px);
  height: s(430px);
  position: absolute;
  left: s(800px);
  top: s(112px);
  background-image: $settings-captions-preview-image;
  background-size: cover;

  .captions-container {
    #captionWindow {
      bottom: s(50px) !important;
      left: 50% !important;
      right: auto !important;
      top: auto !important;

      /* get cues centering properly */
      display: block;
      position: absolute;
      height: auto !important;

      margin: 0 auto;
      padding: 10px;

      transform: translateX(-50%);

      > div {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}
