// TYPOGRAPHY
$page-title: s(58px);
$page-subtitle: s(40px);
$subtitle_2: s(28px);
$page-desc: s(32px);

$title: s(26px);
$subtitle: s(22px);
$label: s(24px);

$menu-title: s(30px);
$contextual-title: s(56px);
$card-border-radius: s(8px);

// CARD SIZES
$card-width: s(330px);
$card-height: s(448px);
$card-thumb-height: s(448px);
$card-offset-x: s(16px);
$card-offset-y: s(16px);

$card-offset-epg-x: s(16px);
$card-offset-epg-y: s(16px);

$video-card-width: s(480px);
$video-card-height: s(432px);
$video-card-thumb-height: s(270px);
$video-card-offset-x: s(16px);
$video-card-offset-y: s(9px);

$channel-card-width: s(392px);
$channel-card-height: s(272px);

$channel-card-width-epg: s(208px);
$channel-card-height-epg: s(165px);

$linear-channel-card-width: s(292px);
$linear-channel-card-height: s(182px);

$featured-card-small-width: s(344px);
$featured-card-small-height: s(208px);

$featured-card-width: s(980px);
$featured-card-height: s(430px);

$live-card-width: s(440px);
$live-card-height: s(574px);

$format-card-width: s(390px);
$format-card-height: s(136px);
$format-card-offset-x: s(16px);
$format-card-offset-y: s(16px);

//
@mixin page-title {
  margin: 0 0 s(40px);
  font-size: $page-title;
  font-weight: 900 !important;
  text-transform: uppercase;
}

@mixin page-subtitle {
  display: block;
  margin: 0 0 s(20px);
  font-size: $page-subtitle;
  font-weight: 600;
}

@mixin page-desc {
  margin: 0 0 s(80px);
  max-width: s(720px);
  font-size: $page-desc;
  font-weight: 100;
  line-height: s(46px);
}

@mixin title {
  font-size: $title;
  font-weight: bold;
}

@mixin subtitle {
  font-size: $subtitle;
  font-weight: bold;
}

@mixin desc {
  font-size: $subtitle;
  font-weight: 100;
  line-height: s(34px);
}

@mixin label {
  font-size: $label;
  font-weight: regular;
}

@mixin text-overflow {
  white-space: nowrap;
  overflow: hidden;
}

// GUTTERS
$gutter: s(20px);
$gutter--large: s(30px);
$gutter--xlarge: s(40px);

$tv-width: s(1920px);
$tv-height: s(1080px);

// PADDING
$safe-area: s(60px) s(100px);

// ANIMATIONS
$animation-curve: ease;
$animation-time: 0.2s;
$animation-time--fast: 0.15s;
$animation-time--slow: 0.3s;

$card-transition: color $animation-time--fast $animation-curve,
  transform $animation-time--fast $animation-curve, opacity $animation-time--fast $animation-curve,
  background-color $animation-time--fast $animation-curve,
  min-height $animation-time--fast $animation-curve, margin $animation-time--fast $animation-curve,
  padding $animation-time--fast $animation-curve, top $animation-time--fast $animation-curve,
  right $animation-time--fast $animation-curve, left $animation-time--fast $animation-curve,
  bottom $animation-time--fast $animation-curve;

@-webkit-keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// ICONS

$icon-size: s(64px);

@mixin icon {
  width: $icon-size;
  height: $icon-size;
}

.blink {
  animation: blink 1s steps(5, start) infinite;
}

@keyframes blink {
  to {
    visibility: hidden;
  }
}

h1 {
  font-weight: 600;
}

h2 {
  font-weight: 100;
}

@mixin day-label {
  height: s(100px);
  width: s(100px);
  padding: s(9px);
  text-align: center;
  box-sizing: border-box;
  h1 {
    display: block;
    font-size: $subtitle_2;
    color: $rb_white;
    //opacity: 0.7;
    margin: 0;
    font-weight: bold;
  }
  h2 {
    display: block;
    margin: 0;
    color: $rb_white;
    //opacity: 0.7;
    font-size: s($subtitle);
    font-weight: 600;
  }
}

.pointerEventsNone {
  pointer-events: none;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: s(1920px);
  height: s(1080px);
  &.fader {
    background: rgba($rb-blue-charcoal, 0.7);
  }
  &.fader_more {
    background: rgba($rb-blue-charcoal, 0.9);
  }
  z-index: 10;
  pointer-events: none;
}

.generic-text-button {
  height: s(100px);
  line-height: s(100px);
  text-align: center;
  white-space: nowrap;
  padding: 0 s(70px);
  color: $rb-white;
  font-size: s(30px);
  &.is-focused {
    background-color: $rb-white;
    padding: 0 s(80px);
    margin: s(-10px) s(-10px);
    color: $rb-grey-100;
  }
}

.conditional-transform {
  // This class is dependent on the build flag --removeTransformZ
  transform: translateZ(0);
}
