.card-progress-bar {
  position: relative;
  height: s(6px);
  background-color: rgba($pure-black, 0.3);
  overflow: hidden;

  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: $pure-white;
  }
}
