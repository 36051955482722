.nerd-mode {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: s(16px);
  padding: s(20px) s(20px) 0;
  background-color: rgba($rb-black, 0.5);
  color: $pure-white;
  overflow: hidden;

  table {
    width: 100%;

    thead {
      tr {
        vertical-align: top;
        th {
          width: 15%;
          padding: s(20px) 0 s(2px);
          font-size: s(18px);
          font-weight: 600;
          text-transform: uppercase;
          text-align: left;
        }
      }
    }

    tbody {
      tr {
        vertical-align: top;
        th {
          // padding: 0 0 0 s(20px);
          font-weight: 600;
          text-align: left;
        }

        td {
          // padding: 0 0 0 s(20px);
          font-weight: 400;
          text-align: left;
        }
      }
    }
  }
}
