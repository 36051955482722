$tab-item-height: s(48px);
$tab-item-margin: s(80px);
$tab-item-border-height: s(4px);
$tab-items-margin: s(40px);

.tab-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 6, 12, 0.5);
  z-index: -1;
  .contextual--closed.has-tabs & {
    top: -$tv-height;
  }
}

.tab-items-wrapper {
  width: 100%;
  height: auto;
  background: rgba(0, 6, 12, 0.8);
  padding: 0 s(250px);
  z-index: 10;
}

.tab__items {
  position: relative;
  padding-bottom: s(40px);
  padding-top: s(56px);
  text-align: center;
}

.tab__item {
  position: relative;
  display: inline-block;
  margin: 0 s(40px);
  height: $tab-item-height;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  text-transform: capitalize;
  font-weight: 600;
  font-size: $menu-title;
  white-space: nowrap;

  &.is-focused {
    color: $rb-alabaster;
    opacity: 1;

    &::after {
      position: absolute;
      bottom: 0;
      left: -2%;
      width: 105%;
      height: $tab-item-border-height;
      background-color: $rb-red;
      border-radius: 1px;
      content: '';
    }
  }

  &.is-active {
    color: $rb-white;
    opacity: 1;

    &::after {
      position: absolute;
      bottom: 0;
      left: -2%;
      width: 105%;
      height: $tab-item-border-height;
      background-color: rgba($rb-white, 0.5);
      border-radius: 1px;
      content: '';
    }
  }
}
