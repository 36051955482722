.card-progress-epg-bar {
  position: relative;
  margin-left: s(2px);
  height: s(7px);
  width: $playlist-card-epg-width - s(32px);
  background-color: transparent;
  overflow: hidden;
  border-bottom-left-radius: $card-border-epg-radius;
  border-bottom-right-radius: $card-border-epg-radius;

  &__inner {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    background-color: $rb-grey-300;

    &--focused {
      background-color: red;
    }
  }
}
