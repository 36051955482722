//$icon-size: s(100px);

.what-is-next-screen {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: s(1920px);
  height: s(1080px);
  background: url('../images/hints-3.jpg');
  background-size: 100% 100%;
}

.what-is-next {
  position: relative;
  width: s(600px);
  height: s(100px);
  opacity: 0;

  &.is-visible {
    opacity: 1;
  }

  &-position {
    position: absolute;
    right: s(100px);
    bottom: s(80px);
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: s(100px);
    height: s(100px);

    > img {
      width: 100%;
      height: 100%;
    }
  }

  &__icon {
    position: absolute;
    top: 0;
    right: 0;
    width: s(100px);
    height: s(100px);
    background-color: rgba($pure-black, 0.55);
    transform: rotate(90deg);

    > svg {
      position: absolute;
      top: 50%;
      left: 50%;
      width: s(50px);
      height: s(50px);
      transform: translate3d(-50%, -50%, 0);
    }

    &__path {
      fill: $pure-white;
    }
  }

  &__label {
    position: absolute;
    top: 0;
    left: s(100px);
    padding: 0 s(20px);
    width: s(400px);
    height: s(100px - 4px);
    background-color: rgba($pure-black, 0.3);
    color: $rb_white;

    &__text {
      display: inline-block;
      margin-top: s(18px);
      font-size: s(22px);
    }

    &__title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: s(30px);
    }
  }

  &__timer {
    position: absolute;
    bottom: 0;
    left: s(100px);
    width: s(400px);
    height: s(4px);
    background-color: rgba($pure-black, 0.2);

    &__progress {
      width: s(100px);
      height: inherit;
      background-color: $pure-white;
    }
  }
}
