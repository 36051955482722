.landing-page__details {
  margin-bottom: 80px;
  padding: 200px 0 0;
  //transition: width 0.2s cubic-bezier(0.14, 1, 0.85, 0.98),transform 0.2s cubic-bezier(0.14, 1, 0.85, 0.98),height 0.2s cubic-bezier(0.14, 1, 0.85, 0.98),background-color 0.2s cubic-bezier(0.14, 1, 0.85, 0.98),color 0.2s cubic-bezier(0.14, 1, 0.85, 0.98),opacity 0.2s cubic-bezier(0.14, 1, 0.85, 0.98);

  &--collapsed {
    position: relative;
    top: -388px;
  }

  &_channel {
    padding: s(100px) 0 0 0;
  }
}

.landing-page__heading {
  text-transform: uppercase;
  font-size: s(22px);
  color: $rb-alabaster;
  line-height: s(32px);
}

.landing-page__title {
  font-family: $cosmos-font-family-title;
  font-size: s(58px);
  line-height: s(96px);
  font-weight: $cosmos-font-weight-bold;
  color: $rb-iron;
  max-width: s(950px);
  margin-bottom: s(10px);
  margin-left: s(16px);
}

.landing-page__title-image {
  img {
    max-width: s(720px);
    height: auto;
    max-height: s(400px);
  }
  margin-bottom: s(20px);
  text-align: left;
  margin-left: s(16px);
}

.landing-page__subtitle {
  font-family: $cosmos-font-family-text;
  font-weight: $cosmos-font-weight-medium;
  font-size: s(30px);
  font-weight: bold;
  line-height: s(37px);
  color: $rb_iron;
  opacity: 1;
  margin-bottom: s(40px);
  margin-left: s(16px);
}

.landing-page-panels {
  //overflow: hidden;
}

.landing-page__desc {
  color: $rb-iron;
  font-family: $cosmos-font-family-text;
  font-weight: $cosmos-font-weight-regular;
  line-height: s(37px);
  font-size: s(30px);
  width: s(1080px);
  margin-left: s(16px);
  margin-bottom: s(50px);
}

.landing-page .lists {
  position: relative;
  top: 0px;
}

.landing-page-list-panel {
  margin-top: -10px;
}

.landing-page .no-scroll .list__item:first-of-type {
  margin-right: 5px; // temp fix for the the btn margin bug on
}
