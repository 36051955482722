.progress-bar {
  position: absolute;
  top: s(77px);
  margin-top: s(15px);
  margin-bottom: s(15px);
  z-index: 100;
}

.progress-bar__bar {
  position: relative;
  margin-left: s(76px); // 16 (left title margin) + 60 (progress bar left margin)
  height: $control-bar-height;
  background-color: rgba($rb-grey-300, 0.7);
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.progress-bar__indicator {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: $pure-white;
  transition: left $animation-time--fast $animation-curve;
  // will-change: left;
}

.progress-bar__indicator,
.progress-bar__text_arrow_container,
.progress-bar__fade_in {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.progress-bar__text {
  color: $pure-white;
  margin: 0 auto;
  text-align: center;
  font-family: $cosmos-font-family-text;
  font-weight: $cosmos-font-weight-regular;
  font-size: s(28px);
  .bold-current {
    font-family: $cosmos-font-family-text;
    font-weight: $cosmos-font-weight-medium;
  }
}

.progress-bar__text_container {
  float: left;
  width: s(130px);
  margin: s(22px) s(80px) 0 s(60px);
}

.progress-bar__text_arrow_container {
  position: absolute;
  top: s(-40px);
  right: 0;
}
