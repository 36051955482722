$label-bg: rgba($rb-black, 0.7);
$label-bg--live: #db0c41;
$label-padding: s(16px);

.card-panel {
  position: absolute;
  bottom: s(16px);
  right: s(16px);
  text-transform: uppercase;
  white-space: nowrap;
  z-index: 6;

  .card-label {
    position: relative;
    display: block;
    padding: 0 s(8px);
    font-size: s(18px);
    font-weight: 400;
    line-height: s(32px);
    border-radius: s(4px);
    color: $rb-white;
    background-color: $label-bg;
    text-transform: uppercase;
    white-space: nowrap;

    &--featured {
      font-size: s(26px);
      line-height: s(50px);
      padding: 0 s(18px) 0 s(20px);
      font-weight: 500;
    }

    &--watching {
      background-color: $label-bg--live;
    }

    &--live {
      padding-left: s(24px);
      background-color: $label-bg--live;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: s(8px);
        width: s(6px);
        height: s(6px);
        margin-top: s(-3px);
        border-radius: s(3px);
        background-color: $rb-white;
        z-index: 2;
        pointer-events: none;
        // will-change: transform;

        @at-root .card.is-focused #{&} {
          animation: dot 1.2s 0.5s infinite ease-in-out;
        }

        @at-root .playlist-card.is-focused #{&} {
          animation: dot 1.2s 0.5s infinite ease-in-out;
        }
      }
    }

    &--delayed {
      background-color: $rb-yellow;
      color: $rb-black;
    }

    &--canceled {
      background-color: $rb-orange;
      color: $rb-black;
    }

    &--upcoming {
      background-color: $rb-yellow;
      color: $rb-black;
    }

    &--unavailable {
      background-color: $rb-orange;
      color: $rb-black;
    }

    // &--watched {
    // }
  }
}

@keyframes dot {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale(1.4);
  }
}
