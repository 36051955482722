@mixin key {
  display: inline-block;
  font-weight: 300;
  font-size: s(35px);
  margin: 0 0 s(16px) 0;
  height: s(63px);
  width: s(63px);
  text-align: center;
  background-size: s(64px) s(64px);
  line-height: s(63px);
  color: $search-keyboard-color;
}

.searchKeyboardPanel {
  width: s(765px);
  height: s(350px);
  position: relative;
  margin-top: s(60px);
  margin-left: s(-4px);
}

.search-keyboard {
  width: s(1665px);
  float: left;

  position: absolute;
  left: 0;
  top: 0;
  .keyboardPanel {
    text-align: left;

    .keyboardKey {
      display: inline-block;
      font-weight: 300;
      font-size: s(35px);
      margin: 0 0 s(16px) 0;
      height: s(63px);
      width: s(63px);
      text-align: center;
      background-size: s(64px) s(64px);
      line-height: s(63px);
      color: $search-keyboard-color;
      position: relative;

      &--image {
        background-repeat: no-repeat;
        background-position: center;
        background-size: s(64px) s(64px);
        vertical-align: top;
        position: relative;

        &.search {
          margin-left: s(185px);
        }
      }

      &--focused {
        &::before {
          content: '';
          width: s(80px);
          height: s(80px);
          position: absolute;
          border-radius: 50%;
          background: $search-keyboard-background--focused;
          transform: translate(-50%, -50%);
          top: 50%;
          left: 50%;
          opacity: 0.3;
        }
      }
    }
  }

  @at-root .is-servus & {
    .keyboardPanel {
      > span {
        &.search {
          margin-left: s(126px);
        }
      }
    }
  }
}
